<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->

  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{
        show:
          shallShowUserProfileSidebar ||
          shallShowActiveChatContactSidebar ||
          mqShallShowLeftSidebar,
      }"
      @click="
        mqShallShowLeftSidebar =
          shallShowActiveChatContactSidebar =
          shallShowUserProfileSidebar =
            false
      "
    />

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Notifications</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="">
          <b-col md="12" v-if="!byRecipients">
            <b-form-group
              label="Select Status"
              invalid-feedback="Status is required."
            >
              <v-select
                v-model="currentSt"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :clearable="false"
                label="text"
                :reduce="(val) => val.value"
                placeholder="Select status"
              />
            </b-form-group>
          </b-col>
          <b-col md="10" v-if="byRecipients">
            <b-form-group
              label="Select Recipients"
              invalid-feedback="Recipients is required."
              ref="recip"
            >
              <v-select
                ref="recip"
                multiple
                :filterBy="dropFilter"
                :closeOnSelect="false"
                v-model="selectedRec"
                :disabled="recLoading"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="contactList"
                :clearable="false"
                placeholder="Select Recipients"
                label="name"
              >
                <!-- @input="setRecep()" -->
                <template slot="option" slot-scope="option">
                  <div class="d-flex align-items-center">
                    <b-avatar
                      v-if="option.avatar == null || option.avatar == ''"
                      size="30"
                      src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                      class="badge-minimal"
                      variant="transparent"
                    />
                    <b-avatar
                      v-else
                      size="30"
                      :src="option.avatar"
                      class="badge-minimal"
                      variant="transparent"
                    />
                    <div class="chat-info flex-grow-1 ml-1">
                      <h5 class="mb-0">
                        {{ option.name }}
                      </h5>
                      <p class="card-text text-truncate">
                        {{ option.details }}
                      </p>
                    </div>
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="10" v-else>
            <b-form-group
              label="Select Class"
              invalid-feedback="class is required."
              ref="class"
            >
              <v-select
                ref="class"
                multiple
                :closeOnSelect="false"
                v-model="classID"
                :disabled="otherLoading"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="classesOptions"
                :clearable="false"
                label="name"
                :reduce="(val) => val.id"
                @input="FillSections()"
                placeholder="Select class"
              />
            </b-form-group>
          </b-col>
          <b-col md="2" class="mt-2" v-if="byRecipients">
            <b-button
              variant="outline-primary"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              v-b-tooltip.hover.top
              title="Close Recipients"
              @click="openMore()"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </b-col>
          <b-col md="2" class="mt-2" v-else>
            <b-button
              variant="outline-primary"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              v-b-tooltip.hover.top
              title="Show Recipients"
              @click="openMore()"
            >
              <feather-icon icon="UsersIcon" />
            </b-button>
          </b-col>
          <b-col
            md="12"
            v-if="
              classID[0] != 0 &&
              classID[0] != -2 &&
              classID[0] != -1 &&
              !byRecipients
            "
          >
            <b-form-group
              label="Select Section"
              invalid-feedback="section is required."
              ref="section"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                v-model="secID"
                :disabled="otherLoading"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="admittedSections"
                :reduce="(val) => val.id"
                :clearable="false"
                @input="checkSections()"
                ref="section"
                label="section"
                placeholder="Select section"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Select template"
              invalid-feedback="Template is required."
              ref="temp"
            >
              <v-select
                v-model="msgID"
                :disabled="otherLoading"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="items"
                :reduce="(val) => val.id"
                :clearable="false"
                @input="LoadMedia()"
                @search:blur="CheckTemp()"
                ref="temp"
                label="title"
                placeholder="Select template"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Message" ref="msg">
              <b-form-textarea
                ref="msg"
                placeholder="your message."
                v-model="myObj.message"
                rows="5"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              ref="date"
              v-if="
                reportDomain == 'myskoolstjacob' &&
                classID[0] != -2 &&
                classID[0] != -1 &&
                myObj.message.includes('{dues}')
              "
            >
              <div class="d-flex justify-content-between">
                <label class="bv-no-focus-ring col-form-label pt-0"
                  >Last visit before
                </label>
                <b-badge
                  variant="primary"
                  class="mb-50 cursor-pointer"
                  @click="currentDate = ''"
                >
                  Clear
                </b-badge>
              </div>
              <flat-pickr
                ref="date"
                :config="config"
                v-model="currentDate"
                class="form-control"
                placeholder="Select Date."
              />
            </b-form-group>
          </b-col>

          <b-col md="12" v-if="hideFields && !byRecipients">
            <b-form-group label="Custom Contacts" ref="custom">
              <b-form-textarea
                ref="custom"
                placeholder="Enter phone number seperated by comma"
                v-model="customContacts"
                min-rows="5"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <!-- && classID[0] != -1 -->
          <b-col md="12" v-if="classID[0] != -2 && !byRecipients">
            <b-button
              variant="primary"
              :disabled="request || otherLoading || draftSending"
              class="mt-2"
              @click="save('')"
              block
            >
              <div v-if="request">
                <b-spinner
                  small
                  variant="light"
                  type="grow"
                  label="Spinning"
                ></b-spinner>
              </div>
              <span v-else>Send</span>
            </b-button>
            <b-button
              v-if="currentSt == 'present'"
              variant="warning"
              :disabled="request || otherLoading || draftSending"
              class="mt-2"
              @click="save('draft')"
              block
            >
              <div v-if="draftSending">
                <b-spinner
                  small
                  variant="light"
                  type="grow"
                  label="Spinning"
                ></b-spinner>
              </div>
              <span v-else>Send as Draft</span>
            </b-button>
            <!-- <b-button
              v-if="fObj && fObj.valueBool && classID[0] != -1"
              variant="primary"
              :disabled="request || otherLoading"
              class="mt-2"
              @click="save()"
              block
            >
              <div v-if="request">
                <b-spinner
                  small
                  variant="light"
                  type="grow"
                  label="Spinning"
                ></b-spinner>
              </div>
              <span v-else>Send Family Wise</span>
            </b-button> -->
          </b-col>

          <!-- WhatsApp -->
          <b-col md="12" v-else-if="byRecipients">
            <b-button
              variant="success"
              :disabled="requestWA || otherLoading"
              class="mt-2"
              @click="WhatsAppRecip()"
              block
            >
              <div v-if="requestWA">
                <b-spinner
                  small
                  variant="light"
                  type="grow"
                  label="Spinning"
                ></b-spinner>
              </div>
              <span v-else>Send WhatsApp</span>
            </b-button>
          </b-col>

          <b-col md="12" v-else>
            <b-button
              variant="success"
              :disabled="requestWA || otherLoading"
              class="mt-2"
              @click="WhatsApp()"
              block
            >
              <div v-if="requestWA">
                <b-spinner
                  small
                  variant="light"
                  type="grow"
                  label="Spinning"
                ></b-spinner>
              </div>
              <span v-else>Send WhatsApp</span>
            </b-button>
          </b-col>

          <!-- Pending -->
          <!-- <b-col md="12" v-if="pendingCount > 0">
            <b-button
              variant="warning"
              :disabled="requestPending || dataLoading"
              class="mt-2"
              @click="SendWhatsApp()"
              block
            >
              <div v-if="requestPending">
                <b-spinner
                  small
                  variant="light"
                  type="grow"
                  label="Spinning"
                ></b-spinner>
              </div>
              <span v-else>Outbox - {{ this.pendingCount }}</span>
            </b-button>
          </b-col> -->

          <!-- Clear Outbox -->
          <b-col md="12" v-if="pendingCount > 0">
            <b-button
              variant="danger"
              class="mt-2"
              @click="ClearOutBox()"
              block
            >
              <div v-if="requestPending">
                <b-spinner
                  small
                  variant="light"
                  type="grow"
                  label="Spinning"
                ></b-spinner>
              </div>
              <span v-else>Clear Outbox</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility4"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Select Recipients</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility4 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="">
          <b-col>
            <b-input-group>
              <b-input-group-prepend is-text style="cursor: pointer">
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchContacts" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
      </b-form>
      <b-table
        thead-class="hidden_header"
        class=""
        :tbody-tr-class="rowClass"
        show-empty
        ref="selectableTable"
        :items="filteredContacts"
        :fields="fields2"
        responsive
        hover
        selectable
        select-mode="multi"
        @row-selected="contactRow"
      >
        <template #cell(details)="data">
          <div class="d-flex">
            <b-avatar
              v-if="data.item.avatar == null || data.item.avatar == ''"
              size="42"
              src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
              class="badge-minimal"
              variant="transparent"
            />
            <b-avatar
              v-else
              size="42"
              :src="data.item.avatar"
              class="badge-minimal"
              variant="transparent"
            />
            <div class="chat-info flex-grow-1 ml-2">
              <h5 class="mb-0">
                {{ data.item.name }}
              </h5>
              <p class="card-text text-truncate">
                {{ data.item.details }}
              </p>
            </div>
          </div>
        </template>
      </b-table>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Groups</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Name"
              invalid-feedback="Name is required."
              ref="grpName"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter group name"
                ref="grpName"
                v-model="groupObj.name"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Custom Contacts" ref="custom">
              <b-form-textarea
                ref="custom"
                placeholder="Enter phone number seperated by comma"
                v-model="groupObj.customContacts"
                min-rows="5"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              variant="primary"
              :disabled="request"
              class="mt-2"
              @click="createGroup()"
              block
            >
              <div v-if="request">
                <b-spinner
                  small
                  variant="light"
                  type="grow"
                  label="Spinning"
                ></b-spinner>
              </div>
              <span v-else>Create</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility3"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Templates</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility3 = false"
        />
      </div>
      <!-- <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12" class="p-0">
            <div
              v-for="(temp, index) in items"
              :key="temp.id"
              class="d-flex pt-0 pl-1 pr-1 pb-1"
            >
              <div class="mr-1">{{ index + 1 }})</div>
              <div v-if="temp.media" class="mr-1">
                <a
                  :href="temp.media"
                  target="_blank"
                  v-if="isImage(temp.media)"
                >
                  <b-img
                    :src="temp.media"
                    style="border-radius: 10px"
                    height="100px"
                    width="100px"
                  />
                </a>

                <a :href="temp.media" target="_blank" v-else>
                  <div class="file-type">
                    {{ temp.media.split(".").pop().toUpperCase() }}
                  </div>
                </a>
              </div>
              <div>
                <h5>{{ temp.message }}</h5>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-form> -->
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col>
            <b-input-group>
              <b-input-group-prepend is-text style="cursor: pointer">
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
      </b-form>

      <b-table
        class="mt-1"
        :tbody-tr-class="rowClass"
        show-empty
        ref="selectableTable"
        :items="filters"
        :fields="fields"
        responsive
        hover
        small
        selectable
        select-mode="single"
        @row-selected="onRowSelected"
      >
        <template #cell(media)="data">
          <div v-if="data.item.media !== null && data.item.media !== ''">
            <a
              :href="data.item.media"
              target="_blank"
              v-if="isImage(data.item.media)"
            >
              <b-img
                :src="data.item.media"
                style="border-radius: 10px"
                height="100px"
                width="100px"
              />
            </a>
            <video
              v-else-if="isVideo(data.item.media)"
              controls
              controlslist="nodownload"
              style="height: 100px; width: 100px"
              @click.stop
            >
              <source :src="data.item.media" type="video/mp4" />
            </video>

            <a :href="data.item.media" target="_blank" v-else>
              <div class="file-type">
                {{ data.item.media.split(".").pop().toUpperCase() }}
              </div>
            </a>
          </div>
          <div v-else>
            <span>No Media</span>
          </div>
        </template>
      </b-table>
      <sidebar-content />
    </b-sidebar>

    <!-- Main Area -->

    <!-- <b-button
      v-if="openedOnce"
      variant="info"
      style="position: fixed; bottom: 1rem; right: 3rem"
      @click="showSidebar()"
    >
      Start
    </b-button> -->
    <!-- <b-row
      style="display: flex; align-items: center; justify-content: center; height: 100%;"
      v-if="dataLoading"
     
    >
      <b-spinner
        style="width: 4rem; height: 4rem"
        type="grow"
        variant="primary"
        label="Spinning"
      ></b-spinner>
    </b-row> -->

    <section class="chat-app-window">
      <!-- // v-if="!dataLoading" -->
      <!-- Start Chat Logo -->
      <div v-if="!opened" class="start-chat-area">
        <div class="mb-1 start-chat-icon">
          <feather-icon icon="EditIcon" size="56" />
        </div>
        <h4 class="sidebar-toggle start-chat-text" @click="showSidebar()">
          Send New Message
        </h4>
      </div>

      <!-- Chat Content -->
      <div v-else class="active-chat">
        <!-- Chat Navbar -->

        <div class="chat-navbar">
          <header class="chat-header">
            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">
              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>

              <b-avatar
                size="36"
                :src="openedContact.avatar"
                class="mr-1 cursor-pointer badge-minimal"
              />
              <!-- badge
                :badge-variant="
                  resolveAvatarBadgeVariant(activeChat.contact.status)
                " -->
              <!-- @click.native="shallShowActiveChatContactSidebar = true" -->
              <div>
                <h6 class="mb-0">
                  {{ openedContact.name }}
                </h6>
                <h6 class="mb-0" style="color: rgb(170 170 176)">
                  {{ openedContact.details }}
                </h6>
              </div>
            </div>

            <!-- Contact Actions -->
            <!-- <div class="d-flex align-items-center">
              <feather-icon
                icon="PhoneCallIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-1"
              />
              <feather-icon
                icon="VideoIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-1"
              />
              <feather-icon
                icon="SearchIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-50"
              />
              <div class="dropdown">
                <b-dropdown variant="link" no-caret toggle-class="p-0" right>
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="17"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item> View Contact </b-dropdown-item>
                  <b-dropdown-item> Mute Notifications </b-dropdown-item>
                  <b-dropdown-item> Block Contact </b-dropdown-item>
                  <b-dropdown-item> Clear Chat </b-dropdown-item>
                  <b-dropdown-item> Report </b-dropdown-item>
                </b-dropdown>
              </div>
            </div> -->
          </header>
        </div>

        <!-- User Chat Area -->
        <div
          ref="refChatLogPS"
          id="chatcontent"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area myscroll"
        >
          <div
            v-if="chatloading"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
            "
          >
            <b-spinner
              style="width: 4rem; height: 4rem"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
          <chat-log
            v-else
            :chat-data="openedContact"
            :user-chat="userChat"
            :profile-user-avatar="profileUserDataMinimal.avatar"
          />
        </div>

        <!-- Message Input -->
        <b-alert
          v-if="openedContact.number == '' || openedContact.number == null"
          show
          variant="danger"
          class="chat-app-form justify-content-center"
        >
          User's contact number is not updated
        </b-alert>
        <b-form class="chat-app-form" @submit.prevent="send()" v-else>
          <b-input-group
            class="input-group-merge form-send-message mr-1"
            ref="message"
          >
            <b-form-input
              class="p-2"
              :disabled="
                openedContact.number == '' || openedContact.number == null
              "
              v-model="inputMessage"
              placeholder="Enter your message"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="MailIcon"
                size="18"
                v-b-tooltip.hover.top
                title="Templates"
                class="cursor-pointer mr-50"
                @click="visibility3 = true"
              />
              <input
                type="file"
                id="fileProfile"
                hidden
                ref="fileProfile"
                @change="fileUpload()"
                accept="image/*,.pdf,video/mp4"
              />
              <b-spinner
                v-if="loading"
                variant="primary"
                small
                label="Spinning"
              ></b-spinner>
              <feather-icon
                v-else
                icon="PaperclipIcon"
                v-b-tooltip.hover.top
                title="Upload"
                size="18"
                @click="$refs.fileProfile.click()"
                style="cursor: pointer"
              />
            </b-input-group-append>
          </b-input-group>
          <b-button
            variant="primary"
            @click="send()"
            :disabled="
              openedContact.number == '' || openedContact.number == null
            "
          >
            Send
          </b-button>
        </b-form>
      </div>
    </section>

    <!-- Active Chat Contact Details Sidebar -->
    <!-- <chat-active-chat-content-details-sidedbar
      :shall-show-active-chat-contact-sidebar.sync="
        shallShowActiveChatContactSidebar
      "
      :contact="activeChat.contact || {}"
    /> -->

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <!-- :active-chat-contact-id="
          activeChat.contact ? activeChat.contact.id : null
        " -->
      <chat-left-sidebar
        :chats-contacts="chatData.chat"
        :contacts="chatData.contacts"
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        :profile-user-minimal-data="profileUserDataMinimal"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        :settings="settings"
        :genSettings="fObj"
        @show-user-profile="showUserProfileSidebar"
        @open-chat="getChat"
        @open-new="showSidebar"
        @open-group="visibility2 = true"
        @load-settings="LoadSettings"
        @load-gen-settings="LoadGenSettings"
        @scroll-handle="scrollHandle"
        :moreChatLoading="moreChatLoading"
        :moreContactLoading="moreContactLoading"
        :dataLoading="dataLoading"
      />
      <!-- v-if="!dataLoading" -->
    </portal>
  </div>
</template>

<script>
import store from "@/store";
import { ref, onUnmounted, nextTick } from "@vue/composition-api";
import {
  BBadge,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BForm,
  BInputGroup,
  BFormInput,
  BButton,
  BFormTextarea,
  BSidebar,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BModal,
  BSpinner,
  BAlert,
  BInputGroupPrepend,
  BInputGroupAppend,
  BImg,
  BTable,
  VBTooltip,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
// import { formatDate } from '@core/utils/filter'
import { $themeBreakpoints } from "@themeConfig";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import ChatLeftSidebar from "./ChatLeftSidebar.vue";
import chatStoreModule from "./chatStoreModule";
import ChatActiveChatContentDetailsSidedbar from "./ChatActiveChatContentDetailsSidedbar.vue";
import ChatLog from "./ChatLog.vue";
import useChat from "./useChat";
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    // BSV
    flatPickr,
    BBadge,
    BTable,
    BSpinner,
    BFormTextarea,
    BFormCheckbox,
    BSidebar,
    BRow,
    BImg,
    BCol,
    BAlert,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormGroup,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    vSelect,
    BModal,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      currentDate: "",
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      msgData: null,
      msgID: 0,
      qrCode: "",
      clientID: "",
      requestWA: false,
      customContacts: "",
      pendingCount: 0,
      requestPending: false,
      myObj: {
        media: "",
        message: "",
        clsIDs: [],
        secIDs: [],
        campusID: this.$store.state.userData.cId,
        userID: this.$store.state.userData.userID,
      },
      groupObj: {
        name: "",
        customContacts: "",
        campusID: this.$store.state.userData.cId,
      },
      visibility4: false,
      visibility3: false,
      visibility2: false,
      visibility: false,
      request: false,
      dataLoading: false,
      otherLoading: false,
      // openedOnce: false,
      statusOptions: [
        { text: "Present", value: "present" },
        { text: "Registered", value: "registered" },
      ],
      currentSt: "present",
      classID: [0],
      secID: [0],
      classes: [],
      classesOptions: [{ name: "All Classes", id: 0 }],
      admittedSections: [{ section: "All Sections", id: 0 }],
      items: [],
      template: "",
      chatData: {
        chat: [],
        contacts: [],
      },
      userChat: [],
      opened: false,
      openedContact: null,
      inputMessage: "",
      media: "",
      loading: false,
      settings: {},
      chatloading: false,
      hideFields: false,
      fields: [
        { label: "media", key: "media" },
        { label: "message", key: "message" },
      ],
      fields2: [{ label: "details", key: "details" }],
      searchQuery: "",
      customObj: {
        contactIDs: [],
        numbers: [],
      },
      selectedRec: [],
      byRecipients: false,
      searchContacts: "",
      contactList: [],
      rights: {},
      fObj: null,
      moreChatLoading: false,
      chatTotal: 0,
      chatPages: 1,
      currentChatPage: 1,
      moreContactLoading: false,
      contactTotal: 0,
      contactPages: 1,
      currentContactPage: 1,
      perPage: 200,
      recLoading: false,
      draftSending: false,
    };
  },
  computed: {
    filters: function () {
      return this.items.filter((pro) => {
        return (
          pro.title.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.message.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
    // using chatData.chat for now later on by api
    filteredContacts: function () {
      return this.contactList.filter((pro) => {
        return pro.name.toLowerCase().match(this.searchContacts.toLowerCase());
      });
    },
  },
  async created() {
    if (!this.$store.state.rights[this.$route.name]) {
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];

      // this.LoadData();
      this.LoadRec();
      this.LoadOthers();
      this.LoadSettings();
      this.LoadGenSettings();
      this.LoadChatList("load");
      // this.LoadContact();

      //Pending whatsapp
      var obj = {
        url:
          this.$store.state.domain +
          "Chat/WhatsAppPendingCount?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      var data = await this.get(obj);
      this.clientID = data.db;
      this.pendingCount = data.data;
    }
  },
  methods: {
    ...mapActions({
      get: "get",
      post: "post",
      postObj: "postObj",
      put: "put",
      delete: "delete",
    }),
    getQR() {
      return this.qrCode;
    },
    showSidebar() {
      this.visibility = true;
    },
    hideSideBar() {
      this.visibility = false;
    },
    contactRow(row) {
      // console.log(row);
      this.customObj.contactIDs = row.reduce((ids, c) => {
        ids.push(c.localID);
        return ids;
      }, []);
      // console.log(this.customObj);
    },
    isImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    },
    isVideo(url) {
      return /\.(mp4)$/.test(url);
    },
    onRowSelected(row) {
      // console.log(row);
      this.inputMessage = row[0].message;
      this.media = row[0].media;
      this.visibility3 = false;
    },
    async getChat(contact) {
      this.inputMessage = "";
      this.media = null;
      this.chatloading = true;
      this.opened = true;
      this.openedContact = contact;

      if (contact.number) {
        var config = {
          url:
            this.$store.state.domain +
            "Chat/LoadChatAdmin?db=" +
            this.$store.state.userData.db +
            "&number=" +
            contact.number,
          token: this.$store.state.userData.token,
        };
        this.userChat = await this.get(config);
      } else this.userChat = [];
      //console.log("chat", this.userChat);

      // this.opened = true;
      this.chatloading = false;
      setTimeout(() => {
        this.setChat();
      }, 200);
    },
    setChat() {
      var elem = this.$refs["refChatLogPS"];
      // console.log(elem);
      elem.scrollTop = elem.scrollHeight;
    },
    fileUpload() {
      var axios = require("axios");
      this.fileProfile = this.$refs.fileProfile.files[0];
      //console.log(this.fileProfile);
      if (this.$refs.fileProfile.files[0] !== "") {
        this.loading = true;

        let formData = new FormData();
        formData.append("file", this.$refs.fileProfile.files[0]);
        ////console.log(this.fileProfile);
        axios
          .post("https://upload.appick.io", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            //console.log(e.data);
            var fn1 = e.data.myresp[0].path;
            this.media = fn1;

            this.loading = false;
          });
      }
    },
    async ClearOutBox() {
      //console.log("clearing...");
      try {
        await this.post({
          url:
            this.$store.state.domain +
            "Chat/ClearOutBox?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: "",
          message: "Outbox cleared.",
          context: this,
          token: this.$store.state.userData.token,
        });

        this.pendingCount = 0;
      } catch (ex) {
      } finally {
      }
    },
    createGroup() {
      if (
        this.groupObj.customContacts.trim() == "" ||
        this.groupObj.name.trim() == ""
      ) {
        this.$bvToast.toast("Please enter the details properly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        console.log(this.groupObj);
      }
    },
    async WhatsAppRecip() {
      this.requestWA = true;
      ////console.log(this.myObj.message);
      try {
        if (this.myObj.message.trim() == 0) {
          this.$bvToast.toast("Please enter message", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        } else if (this.selectedRec.length == 0) {
          this.$bvToast.toast("Please select the Recipients", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        } else {
          // let data = this.customObj.numbers.join();
          // let cObj = {
          //   media: this.msgData.media,
          //   message: this.myObj.message,
          //   contacts: data,
          //   whatsapp: true,
          //   userID: this.$store.state.userData.userID,
          // };

          const stds = this.selectedRec.reduce((ids, el) => {
            el.role.toLowerCase() == "student" && ids.push(el.localID);
            return ids;
          }, []);
          const teas = this.selectedRec.reduce((ids, el) => {
            el.role.toLowerCase() == "teacher" && ids.push(el.localID);
            return ids;
          }, []);

          let cObj = {
            audio: "",
            media: this.msgData.media,
            message: this.myObj.message,
            sIDs: stds,
            tIDs: teas,
            campusID: this.$store.state.userData.cId,
            userID: this.$store.state.userData.userID,
          };
          // console.log(cObj);
          var resp = await this.postObj({
            url:
              this.$store.state.domain +
              "Chat/SendMessageSelected?db=" +
              this.$store.state.userData.db,
            body: cObj,
            message: "WhatsApp messages are being processed.",
            context: this,
            token: this.$store.state.userData.token,
          });
        }
      } catch (error) {
      } finally {
        this.requestWA = false;
      }
    },
    async WhatsApp() {
      this.requestWA = true;
      ////console.log(this.myObj.message);
      try {
        if (this.myObj.message.trim() == 0) {
          this.$bvToast.toast("Please enter message", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        } else if (this.classID[0] == -2 && this.customContacts.trim() == "") {
          this.$bvToast.toast("Please enter contact numbers", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        } else {
          let cObj = {
            media: this.msgData.media,
            message: this.myObj.message,
            contacts: this.customContacts,
            whatsapp: true,
            userID: this.$store.state.userData.userID,
          };
          // console.log(cObj);
          var resp = await this.postObj({
            url:
              this.$store.state.domain +
              "Chat/SendWhatsApp?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId,
            body: cObj,
            message: "WhatsApp messages are being processed.",
            context: this,
            token: this.$store.state.userData.token,
          });
        }
      } catch (error) {
      } finally {
        this.requestWA = false;
      }
    },
    async send() {
      //console.log(this.inputMessage);
      if (this.inputMessage.trim() !== "") {
        let obj = {
          message: this.inputMessage,
          audio: "",
          media: this.media,
          localID: this.openedContact.localID,
          role: this.openedContact.role,
          campusID: this.$store.state.userData.cId,
          userID: this.$store.state.userData.userID,
        };
        // if (this.userChat.length !== 0) {
        //   if (this.userChat[0].sID == null) {
        //     obj.localID = this.userChat[0].tID;
        //     obj.role = "teacher";
        //   } else {
        //     obj.localID = this.userChat[0].sID;
        //     obj.role = "student";
        //   }
        // } else {
        //   if (this.openedContact.role == "student") {
        //     obj.localID = this.openedContact.localID;
        //     obj.role = "student";
        //   } else {
        //     obj.localID = this.openedContact.localID;
        //     obj.role = "teacher";
        //   }
        // }

        //console.log(obj);
        var axios = require("axios");
        let config = {
          method: "post",
          url:
            this.$store.state.domain +
            "Chat/SendSingleMessage?db=" +
            this.$store.state.userData.db,
          data: obj,
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
        };
        axios(config)
          .then((res) => {
            this.getChat(this.openedContact);
            this.inputMessage = "";
          })
          .catch((err) => {
            //console.log(err);
          });
      }
    },
    async LoadOthers() {
      this.otherLoading = true;
      var obj2 = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      this.classes = await this.get(obj2);
      //console.log("class", this.classes);
      this.classes.forEach((el) => this.classesOptions.push(el.cls));
      this.classesOptions.push(
        { name: "All Staff", id: -1 },
        { name: "Custom Contacts", id: -2 }
      ); //Custom Contacts
      // console.log(this.classesOptions);

      var obj = {
        url:
          this.$store.state.domain +
          "MessageTemplate?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      this.items = this.items.filter(
        (el) => el.sendOn == "" || el.sendOn == "Defaulters"
      );
      //console.log("temp", this.items);
      this.otherLoading = false;
    },
    async LoadData() {
      this.dataLoading = true;

      var obj2 = {
        url:
          this.$store.state.domain +
          "Chat/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj2);

      if (!Array.isArray(res)) {
        this.chatData = res;
        this.contactList = [...this.chatData.chat, ...this.chatData.contacts];
        this.contactList = this.contactList.filter(
          (el) => el.number != "" && el.number != null
        );
      }
      // console.log("data", this.chatData);
      // console.log(this.contactList);
      this.dataLoading = false;
      // this.LoadSettings();
    },
    async LoadRec() {
      this.recLoading = true;
      var obj2 = {
        url:
          this.$store.state.domain +
          "Chat/LoadRecipients?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj2);
      // console.log("rece", res);
      this.contactList = res.filter(
        (el) => el.number != "" && el.number != null
      );
      this.recLoading = false;
    },
    async LoadChatList(text) {
      this.moreChatLoading = true;

      var obj = {
        url:
          this.$store.state.domain +
          "Chat/LoadChatPagination?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&page=" +
          this.currentChatPage,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      // console.log(res);
      if (!Array.isArray(res)) {
        this.currentChatPage++;
        this.chatTotal = res.total;
        this.chatPages = Math.ceil(this.chatTotal / this.perPage);
        if (text == "load" && this.chatPages <= 1) this.LoadContact();
        this.chatData.chat = [...this.chatData.chat, ...res.chat];
        // let clist = res.chat.filter(
        //   (el) => el.number != "" && el.number != null
        // );
        // this.contactList = [...this.contactList, ...clist];

        // console.log(
        //   "count:",
        //   this.chatTotal,
        //   "pages",
        //   this.chatPages,
        //   "current",
        //   this.currentChatPage
        // );
      }
      this.moreChatLoading = false;
    },
    async LoadContact() {
      this.moreContactLoading = true;

      var obj = {
        url:
          this.$store.state.domain +
          "Chat/LoadContactPagination?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&page=" +
          this.currentContactPage,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      // console.log(res);
      if (!Array.isArray(res)) {
        this.currentContactPage++;
        this.contactTotal = res.total;
        this.contactPages = Math.ceil(this.contactTotal / this.perPage);
        this.chatData.contacts = [...this.chatData.contacts, ...res.chat];
        // let clist = res.chat.filter(
        //   (el) => el.number != "" && el.number != null
        // );
        // this.contactList = [...this.contactList, ...clist];

        // console.log(
        //   "count:",
        //   this.contactTotal,
        //   "pages",
        //   this.contactPages,
        //   "current",
        //   this.currentContactPage
        // );
      }
      this.moreContactLoading = false;
    },
    scrollHandle(el) {
      // console.log(el);
      // console.log(el.scrollHeight - el.scrollTop <= el.clientHeight + 200);
      if (el.scrollHeight - el.scrollTop <= el.clientHeight + 200) {
        if (!this.moreChatLoading && this.currentChatPage <= this.chatPages) {
          // console.log("load chat", this.currentChatPage);
          this.LoadChatList();
        }
        if (
          !this.moreContactLoading &&
          !this.moreChatLoading &&
          this.currentChatPage >= this.chatPages &&
          this.currentContactPage <= this.contactPages
        ) {
          // console.log("load contact", this.currentContactPage);
          this.LoadContact();
        }
      }
    },

    async LoadSettings() {
      var obj = {
        url:
          this.$store.state.domain +
          "NotificationSettings/LoadData?campusID=" +
          this.$store.state.userData.cId +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      let result = await this.get(obj);

      if (result == null) {
        this.settings = {
          id: 0,
          campusID: this.$store.state.userData.cId,
          sendNotification: false,
          sendSMS: false,
          contact_father: false,
          contact_mother: false,
          contact_student: false,
        };
      } else {
        this.settings = result;
      }
      //console.log("set", this.settings);
    },
    async LoadGenSettings() {
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: ["family_wise_message"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });
      let obj = res.find((el) => el.key == "family_wise_message");
      if (obj) this.fObj = obj;
      else {
        this.fObj = {
          id: 0,
          campusID: this.$store.state.userData.cId,
          key: "family_wise_message",
          value: "family_wise_message",
          valueBool: false,
          valueNumber: null,
        };
      }
      // console.log(this.fObj);
    },
    openMore() {
      this.byRecipients = !this.byRecipients;
      let a = this.currentSt;
      setTimeout(() => {
        this.currentSt = a;
      }, 200);
      // this.visibility4 = true;
    },
    dropFilter(opt, label, search) {
      // console.log(opt, label, search);
      return (
        opt.name.toLowerCase().match(search.toLowerCase()) ||
        opt.details.toLowerCase().match(search.toLowerCase())
      );
    },
    setRecep() {
      this.customObj.numbers = this.selectedRec.reduce((nums, el) => {
        nums.push(el.number);
        return nums;
      }, []);
      // console.log(this.customObj.numbers);
    },
    async LoadMedia() {
      this.msgData = this.items.find((el) => el.id == this.msgID);
      // console.log(this.msgID, this.msgData);
      // var mObj = {
      //   url:
      //     this.$store.state.domain +
      //     "MessageTemplate/GetSelected/?id=" +
      //     this.msgID +
      //     "&db=" +
      //     this.$store.state.userData.db +
      //     "&cID=" +
      //     this.$store.state.userData.cId,
      //   token: this.$store.state.userData.token,
      // };
      // this.msgData = await this.get(mObj);
      this.myObj.message = this.msgData.message;
      this.myObj.media = this.msgData.media;
      // console.log("######### obj", this.myObj);
    },
    FillSections() {
      //return //console.log(this.classes);
      //console.log(this.classID);
      //   if (this.classID.includes(0) && this.classID.length == 2) {
      //     this.classID = this.classID.filter((e) => e !== 0);
      //   }

      // console.log(this.classID);
      if (
        this.classID[this.classID.length - 1] == -2 ||
        this.classID[this.classID.length - 1] == -1
      ) {
        this.classID = [this.classID[this.classID.length - 1]]; // will be -2 or -1

        this.admittedSections = [{ section: "All Sections", id: 0 }];
        if (this.classID[0] == -2) this.hideFields = true;
        else this.hideFields = false;
      } else {
        this.classID = this.classID.filter((e) => e !== -2 && e !== -1);
        this.hideFields = false;

        if (this.classID[0] == 0 && this.classID.length !== 1) {
          this.classID = this.classID.filter((e) => e !== 0);
          //console.log(this.classID);
          this.classes.forEach((elem) => {
            if (this.classID.includes(elem.cls.id)) {
              if (elem.sections.length != 0) {
                elem.sections.forEach((el) => this.admittedSections.push(el));
              } else {
                // console.log("null");
                this.admittedSections = [{ section: "All Sections", id: 0 }];
              }
            }
          });
        } else if (
          this.classID[this.classID.length - 1] !== 0 &&
          this.classID.length == 1
        ) {
          this.classes.forEach((elem) => {
            if (this.classID.includes(elem.cls.id)) {
              if (elem.sections.length != 0) {
                elem.sections.forEach((el) => this.admittedSections.push(el));
              } else {
                // console.log("null");
                this.admittedSections = [{ section: "All Sections", id: 0 }];
              }
            }
          });
        } else if (this.classID[this.classID.length - 1] == 0) {
          this.classID = [0];
          // console.log(this.classID);
          this.admittedSections = [{ section: "All Sections", id: 0 }];
          this.secID = [0];
        } else {
          this.admittedSections = [{ section: "All Sections", id: 0 }];
          this.secID = [0];
        }
      }
      // console.log(this.classID);
      this.CheckClass();
    },
    checkSections() {
      //console.log(this.secID);
      if (this.secID[0] == 0 && this.secID.length !== 1) {
        this.secID = this.secID.filter((e) => e !== 0);
        //console.log(this.secID);
      } else if (this.secID[this.secID.length - 1] == 0) {
        this.secID = [0];
        //console.log(this.secID);
      }
      this.CheckSec();
    },
    CheckClass() {
      // //console.log(this.my);
      var elem = this.$refs["class"];
      if (this.classID.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSec() {
      if (this.classID[0] != 0 && this.classID[0] != -1) {
        var elem = this.$refs["section"];
        if (this.secID.length == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckTemp() {
      var elem = this.$refs["temp"];
      if (this.msgID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async save(type) {
      // this.myObj.media = null;
      // this.myObj.media = this.msgData.media;
      // console.log(this.myObj);
      this.CheckClass();
      this.CheckSec();
      this.CheckTemp();
      if (
        this.CheckClass() == false ||
        this.CheckSec() == false ||
        this.CheckTemp() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else if (this.myObj.message.trim() === "") {
        return this.$bvToast.toast("Please enter message", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        // for staff
        if (this.classID[0] == -1) {
          this.myObj.clsIDs = [-1];
          this.myObj.secIDs = [];
        } else {
          if (this.classID[0] !== 0) {
            this.myObj.clsIDs = this.classID;
          } else {
            // this.myObj.clsIDs = [];
            this.myObj.clsIDs = this.classes.map((el) => el.cls.id);
          }
          if (this.secID[0] !== 0) {
            ////console.log(this.secID);
            this.myObj.secIDs = this.secID;
          } else {
            this.myObj.secIDs = [];
          }
        }
        //Add
        let endpoint = "Chat/SendMessage";
        let msg = "Message sent successfully.";

        if (type == "draft") {
          this.draftSending = true;
          msg = "Message saved as draft successfully.";
          endpoint = "Chat/SaveAsDraft";
        } else {
          this.request = true;
          if (this.currentSt == "registered")
            endpoint = "Chat/SendMessageRegistered";
        }

        // console.log("obj:", this.myObj);
        let dt = "";
        if (
          this.reportDomain == "myskoolstjacob" &&
          this.classID[0] != -2 &&
          this.classID[0] != -1 &&
          this.myObj.message.includes("{dues}")
        ) {
          dt = this.currentDate;
        }

        var status = await this.post({
          url:
            this.$store.state.domain +
            endpoint +
            "?db=" +
            this.$store.state.userData.db +
            "&date=" +
            dt,
          body: this.myObj,
          message: msg,
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        this.draftSending = false;
        // this.myObj.media = null;
        if (status) this.visibility = false;
      }
    },
  },
  setup() {
    const CHAT_APP_STORE_MODULE_NAME = "app-chat";

    // Register module
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME))
      store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CHAT_APP_STORE_MODULE_NAME))
        store.unregisterModule(CHAT_APP_STORE_MODULE_NAME);
    });

    const { resolveAvatarBadgeVariant } = useChat();

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null);
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value;
      scrollEl.scrollTop = scrollEl.scrollHeight;
    };

    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------
    const chatsContacts = ref([]);
    const contacts = ref([]);

    const fetchChatAndContacts = () => {
      store.dispatch("app-chat/fetchChatsAndContacts").then((response) => {
        chatsContacts.value = response.data.chatsContacts;
        contacts.value = response.data.contacts;
        // eslint-disable-next-line no-use-before-define
        profileUserDataMinimal.value = response.data.profileUser;
      });
    };

    fetchChatAndContacts();

    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------
    const activeChat = ref({});
    const chatInputMessage = ref("");
    const userChat = ref([]);

    const openedOnce = ref(false);
    const opened = ref(false);
    const openChatOfContact = (contact) => {
      // Reset send message input value
      // chatInputMessage.value = "";
      // openedOnce.value = true;

      // store.dispatch("app-chat/getChat", { userId }).then((response) => {
      //   activeChat.value = response.data;

      //   // Set unseenMsgs to 0
      //   const contact = chatsContacts.value.find((c) => c.id === userId);
      //   if (contact) contact.chat.unseenMsgs = 0;

      //   // Scroll to bottom
      // });
      // const openedContact = ref({});

      // openedContact.value = contact;
      // var config = {
      //   url:
      //     store.state.domain +
      //     "Chat/LoadChatAdmin?db=" +
      //     store.state.userData.db +
      //     "&number=" +
      //     contact.number,
      //   headers: {
      //     Authorization: "bearer " + this.$store.state.userData.token,
      //   },
      // };
      // axios(config)
      //   .then((res) => {
      //     if (res.data.status == "success") {
      //       userChat.value = res.data.data;
      //       //console.log("chat", userChat);
      //       opened.value = true;
      //       nextTick(() => {
      //         scrollToBottomInChatLog();
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     //console.log(err);
      //   });

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false;
    };
    const sendMessage = () => {
      if (!chatInputMessage.value) return;
      const payload = {
        contactId: activeChat.value.contact.id,
        // eslint-disable-next-line no-use-before-define
        senderId: profileUserDataMinimal.value.id,
        message: chatInputMessage.value,
      };
      store.dispatch("app-chat/sendMessage", payload).then((response) => {
        const { newMessageData, chat } = response.data;

        // ? If it's not undefined => New chat is created (Contact is not in list of chats)
        if (chat !== undefined) {
          activeChat.value = { chat, contact: activeChat.value.contact };
          chatsContacts.value.push({
            ...activeChat.value.contact,
            chat: {
              id: chat.id,
              lastMessage: newMessageData,
              unseenMsgs: 0,
            },
          });
        } else {
          // Add message to log
          activeChat.value.chat.chat.push(newMessageData);
        }

        // Reset send message input value
        chatInputMessage.value = "";

        // Set Last Message for active contact
        const contact = chatsContacts.value.find(
          (c) => c.id === activeChat.value.contact.id
        );
        contact.chat.lastMessage = newMessageData;

        // Scroll to bottom
        nextTick(() => {
          scrollToBottomInChatLog();
        });
      });
    };

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    // User Profile Sidebar
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({});
    // ? Will contain id, name and avatar & status
    const profileUserDataMinimal = ref({});

    const shallShowUserProfileSidebar = ref(false);
    const showUserProfileSidebar = () => {
      store.dispatch("app-chat/getProfileUser").then((response) => {
        profileUserData.value = response.data;
        shallShowUserProfileSidebar.value = true;
      });
    };

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false);

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true;
      }
    };

    return {
      // Filters
      // formatDate,

      // useChat
      resolveAvatarBadgeVariant,

      // Chat & Contacts
      chatsContacts,
      contacts,

      // Single Chat
      refChatLogPS,
      activeChat,
      chatInputMessage,
      openedOnce,
      openChatOfContact,
      sendMessage,
      userChat,
      // openedContact,
      // opened,

      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      showUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
    };
  },
};
</script>

<style lang="css" scoped>
.myscroll {
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.myscroll::-webkit-scrollbar {
  width: 10px;
}
.myscroll::-webkit-scrollbar-track {
  border: none;
  background-color: transparent;
  border-radius: 20px;
}
.myscroll::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: lightgray;
}
.file-type {
  background-color: #e62e2d;
  font-family: "Helvetica", sans-serif;
  color: white;
  border-radius: 10px;
  margin-right: 15px;
  height: 80px;
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
</style>
<style>
.hidden_header {
  display: none;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
