<template>
  <div class="sidebar-left">
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Settings</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="pt-1 pb-1">
          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <h4 class="mr-1">App Notifications</h4>
            <b-form-checkbox v-model="settings.sendNotification" switch />
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <h4 class="mr-1">WhatsApp</h4>
            <b-form-checkbox v-model="settings.sendSMS" switch />
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <h4 class="mr-1">Send to Father</h4>
            <b-form-checkbox v-model="settings.contact_father" switch />
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <h4 class="mr-1">Send to Mother</h4>
            <b-form-checkbox v-model="settings.contact_mother" switch />
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <h4 class="mr-1">Send to Student</h4>
            <b-form-checkbox v-model="settings.contact_student" switch />
          </b-col>
          <b-col
            v-if="genSettings"
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <h4 class="mr-1">Family Wise SMS</h4>
            <b-form-checkbox v-model="genSettings.valueBool" switch />
          </b-col>
          <!-- <b-col md="12">
            <h4>WhatsApp delay</h4>
            <v-select
              ref="whats"
              v-model="settings.delay"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="delayOptions"
              :reduce="(val) => val.value"
              label="text"
              :clearable="false"
              placeholder="select delay time"
            /> 
          </b-col> -->

          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              class="mr-1 fullWidth"
              @click="saveSettings()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> Save </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>
    <div class="sidebar">
      <!-- Logged In User Profile Sidebar -->
      <user-profile-sidebar
        :shall-show-user-profile-sidebar="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        @close-sidebar="$emit('update:shall-show-user-profile-sidebar', false)"
      />

      <!-- Sidebar Content -->
      <div class="sidebar-content" :class="{ show: mqShallShowLeftSidebar }">
        <!-- Sidebar close icon -->
        <span class="sidebar-close-icon">
          <feather-icon
            icon="XIcon"
            size="16"
            @click="$emit('update:mq-shall-show-left-sidebar', false)"
          />
        </span>

        <!-- Header -->
        <div class="chat-fixed-search">
          <!-- style="display: block; padding: 1.286rem; height: auto" -->
          <div class="d-flex align-items-center w-100">
            <div class="sidebar-profile-toggle">
              <!-- <b-avatar
                size="42"
                class="cursor-pointer badge-minimal avatar-border-2"
                :src="profileUserMinimalData.avatar"
                variant="transparent"
                badge
                badge-variant="success"
                @click.native="$emit('show-user-profile')"
              /> -->
              <!-- <b-avatar size="42" variant="light-primary">
                <feather-icon size="24" icon="UserIcon" />
              </b-avatar> -->
            </div>
            <!-- Search -->
            <b-input-group class="input-group-merge w-100 round">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" class="text-muted" />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchValue"
                placeholder="Search..."
                @keyup.enter="loadSearch()"
              />
            </b-input-group>
            <feather-icon
              v-if="$store.state.rights['apps-groups']"
              class="ml-1 cursor-pointer"
              icon="UsersIcon"
              size="30"
              v-b-tooltip.hover.top
              title="Manage Groups"
              @click="openGroup()"
            />
            <!-- @click="$emit('open-group')" -->
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="EditIcon"
              size="30"
              v-b-tooltip.hover.top
              title="New Message"
              @click="$emit('open-new')"
            />
            <feather-icon
              v-if="$store.state.rights['outbox']"
              class="ml-1 cursor-pointer"
              icon="MailIcon"
              size="30"
              v-b-tooltip.hover.top
              title="Outbox"
              @click="openOutbox()"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="SettingsIcon"
              size="30"
              v-b-tooltip.hover.top
              title="Settings"
              @click="showSettings()"
            />
          </div>
          <!-- <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="SettingsIcon"
              size="30"
              @click="showSettings()"
            />
          </div> -->
        </div>

        <!-- ScrollArea: Chat & Contacts -->
        <!-- <h4 class="chat-list-title" v-if="filteredChatsContacts.length == 0">
          Chats
        </h4> -->

        <!-- v-else -->
        <vue-perfect-scrollbar
          ref="chatListPS"
          :settings="perfectScrollbarSettings"
          class="chat-user-list-wrapper list-group scroll-area"
          @ps-scroll-y="handleScroll"
        >
          <!-- Chats Title -->
          <h4 class="chat-list-title">Chats</h4>

          <!-- Chats -->
          <ul class="chat-users-list chat-list media-list">
            <div v-if="dataLoading">
              <vue-content-loading
                :width="300"
                :height="60"
                class="ml-1"
                v-for="n in 5"
                :key="n"
              >
                <circle cx="30" cy="30" r="23" />
                <rect x="75" y="13" rx="3" ry="3" width="200" height="13" />
                <rect x="75" y="34" rx="3" ry="3" width="150" height="10" />
              </vue-content-loading>
            </div>
            <template v-else-if="bySearch">
              <chat-contact
                v-for="(contact, ind) in searchedChats"
                :key="'chat' + String(ind)"
                :user="contact"
                tag="li"
                is-chat-contact
                @click="$emit('open-chat', contact)"
              />
            </template>
            <template v-else>
              <chat-contact
                v-for="(contact, ind) in filteredChatsContacts"
                :key="'chat' + String(ind)"
                :user="contact"
                tag="li"
                is-chat-contact
                @click="$emit('open-chat', contact)"
              />
              <!-- :class="{ active: activeChatContactId === contact.id }" -->
            </template>
            <div v-if="moreChatLoading">
              <vue-content-loading
                :width="300"
                :height="60"
                class="ml-1"
                v-for="n in 5"
                :key="n"
              >
                <circle cx="30" cy="30" r="23" />
                <rect x="75" y="13" rx="3" ry="3" width="200" height="13" />
                <rect x="75" y="34" rx="3" ry="3" width="150" height="10" />
              </vue-content-loading>
            </div>
          </ul>

          <!-- Contacts Title -->
          <h4 class="chat-list-title">Contacts</h4>

          <!-- Contacts -->
          <ul class="chat-users-list contact-list media-list">
            <div v-if="dataLoading">
              <vue-content-loading
                :width="300"
                :height="60"
                class="ml-1"
                v-for="n in 5"
                :key="n"
              >
                <circle cx="30" cy="30" r="23" />
                <rect x="75" y="13" rx="3" ry="3" width="200" height="13" />
                <rect x="75" y="34" rx="3" ry="3" width="150" height="10" />
              </vue-content-loading>
            </div>
            <template v-else-if="bySearch">
              <chat-contact
                v-for="(contact, ind) in searchedContacts"
                :key="'chat' + String(ind)"
                :user="contact"
                tag="li"
                is-chat-contact
                @click="$emit('open-chat', contact)"
              />
            </template>
            <template v-else>
              <chat-contact
                v-for="(contact, ind) in filteredContacts"
                :key="'con' + String(ind)"
                :user="contact"
                tag="li"
                @click="$emit('open-chat', contact)"
              />
            </template>
            <div v-if="moreContactLoading">
              <vue-content-loading
                :width="300"
                :height="60"
                class="ml-1"
                v-for="n in 5"
                :key="n"
              >
                <circle cx="30" cy="30" r="23" />
                <rect x="75" y="13" rx="3" ry="3" width="200" height="13" />
                <rect x="75" y="34" rx="3" ry="3" width="150" height="10" />
              </vue-content-loading>
            </div>
          </ul>
        </vue-perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormGroup,
  BModal,
  BFormCheckbox,
  BRow,
  BCol,
  BForm,
  VBTooltip,
  BSidebar,
  BSpinner,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { ref, computed } from "@vue/composition-api";
import ChatContact from "./ChatContact.vue";
import UserProfileSidebar from "./UserProfileSidebar.vue";
import { mapActions, mapGetters } from "vuex";

import { VclBulletList, VueContentLoading } from "vue-content-loading";

export default {
  components: {
    // BSV
    VclBulletList,
    VueContentLoading,
    BSidebar,
    VBTooltip,
    BSpinner,
    BAvatar,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BModal,
    BRow,
    BCol,
    vSelect,
    BFormGroup,

    // 3rd party
    VuePerfectScrollbar,

    // SFC
    ChatContact,
    UserProfileSidebar,
  },
  props: {
    moreChatLoading: {
      type: Boolean,
      required: true,
    },
    moreContactLoading: {
      type: Boolean,
      required: true,
    },
    dataLoading: {
      type: Boolean,
      required: true,
    },
    chatsContacts: {
      type: Array,
      required: true,
    },
    contacts: {
      type: Array,
      required: true,
    },
    shallShowUserProfileSidebar: {
      type: Boolean,
      required: true,
    },
    profileUserData: {
      type: Object,
      required: true,
    },
    profileUserMinimalData: {
      type: Object,
      required: true,
    },
    activeChatContactId: {
      type: Number,
      default: null,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
    genSettings: {
      type: Object,
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  created() {},
  data() {
    return {
      settings: {
        id: 0,
        campusID: this.$store.state.userData.cId,
        sendNotification: false,
        sendSMS: false,
        contact_father: false,
        contact_mother: false,
        contact_student: false,
      },
      request: false,
      visibility: false,
      chatData: {},
      delayOptions: [
        { text: "5 seconds", value: 5 },
        { text: "6 seconds", value: 6 },
        { text: "7 seconds", value: 7 },
        { text: "8 seconds", value: 8 },
        { text: "9 seconds", value: 9 },
        { text: "10 seconds", value: 10 },
        { text: "11 seconds", value: 11 },
        { text: "12 seconds", value: 12 },
      ],
      bySearch: false,
      searchValue: "",
      searchedChats: [],
      searchedContacts: [],
    };
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    const resolveChatContact = (userId) =>
      props.contacts.find((contact) => contact.id === userId);

    // Search Query
    const searchQuery = ref("");

    const searchFilterFunction = (contact) =>
      contact.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      contact.details.toLowerCase().includes(searchQuery.value.toLowerCase());
    const filteredChatsContacts = computed(() =>
      props.chatsContacts.filter(searchFilterFunction)
    );
    const filteredContacts = computed(() =>
      props.contacts.filter(searchFilterFunction)
    );

    return {
      // Search Query
      searchQuery,
      filteredChatsContacts,
      filteredContacts,

      // UI
      resolveChatContact,
      perfectScrollbarSettings,
    };
  },

  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),
    openOutbox() {
      this.$router.push("/outbox");
    },
    openGroup() {
      this.$router.push("/groups");
    },
    showSettings() {
      this.visibility = true;
    },
    hideSideBar() {
      this.visibility = false;
    },
    async saveSettings() {
      this.request = true;
      var status = await this.post({
        url:
          this.$store.state.domain +
          "NotificationSettings/Save?db=" +
          this.$store.state.userData.db,
        body: this.settings,
        message: "Settings added successfully.",
        context: this,
        token: this.$store.state.userData.token,
      });
      this.request = false;
      if (status) {
        this.visibility = false;
        this.$emit("load-settings");
      }

      if (this.genSettings) {
        var sett = await this.post({
          url:
            this.$store.state.domain +
            "Settings/SaveNew?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: [this.genSettings],
          importing: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (sett) {
          this.$emit("load-gen-settings");
        }
      }
    },
    handleScroll(evt) {
      // console.log(evt);
      if (!this.dataLoading && !this.bySearch) {
        const container = evt.target;
        this.$emit("scroll-handle", container);
      }
    },
    scrollUp() {
      var elem = this.$refs["chatListPS"].$el || this.$refs["chatListPS"];
      if (elem) elem.scrollTop = 0;
    },
    async loadSearch() {
      if (!this.moreChatLoading && !this.moreContactLoading) {
        if (this.searchValue.trim() == "") {
          this.scrollUp();
          this.bySearch = false;
        } else {
          this.scrollUp();

          this.bySearch = true;
          this.dataLoading = true;
          var obj = {
            url:
              this.$store.state.domain +
              "Chat/SearchAdmin?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId +
              "&val=" +
              this.searchValue.trim(),
            token: this.$store.state.userData.token,
          };
          var data = await this.get(obj);
          // console.log(data);

          this.searchedChats = data.chat;
          this.searchedContacts = data.contacts;
          this.dataLoading = false;
          this.scrollUp();
        }
      }
    },
  },
};
</script>

<style scoped lang="css">
/* .chat-fixed-search {
  display: block;
} */
</style>
